import React from "react";
import { Link, NavLink } from "react-router-dom";
import data from "../db.json";

const MagazineHome = () => {
  const release1_content1 = data.articles.slice(0, 9);
  const release1_content2 = data.articles.slice(9, 18);

  const release2_content1 = data.articles2.slice(0, 4);
  const release2_content2 = data.articles2.slice(4, 8);
  return (
    <div className="">
      <div className="text-center">
        <h1 className="font-bold text-4xl mt-2">চির সবুজ</h1>
        <h3 className="mt-3 font-bold text-2xl">সূচীপত্র</h3>
      </div>

      {/* Release 2 */}
      <div>
        <h1 className="mt-5 font-bold text-3xl">১৭ই ফেব্রুয়ারী, ২০২৪</h1>

        <div className="mt-5 text-lg text-left flex flex-col sm:flex-row justify-center sm:gap-5">
          <div className="mx-5 sm:mx-16 sm:w-1/2">
            {release2_content1.map((article, index) => {
              return (
                
                  article.url ? <p className="hover:underline mb-3">

                  <Link
                      target="_blank"
                      to={article?.url}
                      className="hover:underline mb-3"
                    >
                      {article.id}. {article.title}
                    </Link> 
                  </p> :
                <p key={index} className="hover:underline mb-3">
                  <NavLink to={`/magazine/release2/article/${article.id}`}>
                    {article.id}. {article.title}
                  </NavLink>
                </p>
                
              );
            })}
          </div>
          <div className="mx-5 sm:mx-16 sm:w-1/2">
            {release2_content2.map((article, index) => {
              return (
                <p key={index} className="hover:underline mb-3">
                  <NavLink to={`/magazine/release2/article/${article.id}`}>
                    {article.id}. {article.title}
                  </NavLink>
                </p>
              );
            })}
          </div>
        </div>
      </div>

      
      {/* Release 1 */}
      <div>
        <h1 className="mt-5 font-bold text-3xl">১৫ই আগস্ট, ২০২৩</h1>

        <div className="mt-5 text-lg text-left flex flex-col sm:flex-row justify-center sm:gap-5">
          <div className="mx-5 sm:mx-16 sm:w-1/2">
            {release1_content1.map((article, index) => {
              return (
                <p key={index} className="hover:underline mb-3">
                  <NavLink to={`/magazine/article/${article.id}`}>
                    {article.id}. {article.title}
                  </NavLink>
                </p>
              );
            })}
          </div>
          <div className="mx-5 sm:mx-16 sm:w-1/2">
            {release1_content2.map((article, index) => {
              return (
                <p key={index} className="hover:underline mb-3">
                  <NavLink to={`/magazine/article/${article.id}`}>
                    {article.id}. {article.title}
                  </NavLink>
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MagazineHome;
