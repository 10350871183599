import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const activeLink = "font-bold";
  return (
    <div className="z-20">
      <header className="flex flex-col pt-5 sm:flex-row  sm:pt-10 sm:px-10 sm:justify-between sm:items-end">
        <div
          className="flex justify-center sm:flex-row cursor-pointer"
          onClick={() => navigate("/")}
        >
          <h1 className="font-heading text-3xl sm:text-5xl font-semibold">চির সবুজ </h1>
          <span className="text-3xl sm:text-4xl pl-2">(The Evergreen)</span>
        </div>
        <div className="text-sm sm:text-base flex flex-wrap gap-1 justify-center pt-3 sm:pt-0">
          {/* <vr className="w-[2px] mr-3 bg-gray-700 border-0" /> */}
          <NavLink
            to="/disclaimer"
            className={({ isActive }) =>
              isActive ? activeLink : "hover:underline"
            }
          >
            Disclaimer
          </NavLink>
          <vr className="w-[2px] mx-3 bg-gray-700 border-0" />
          <NavLink
            to="/magazine"
            className={({ isActive }) =>
              isActive ? activeLink : "hover:underline"
            }
          >
            e - Magazine
          </NavLink>
          <vr className="w-[2px] mx-3 bg-gray-700 border-0" />
          <NavLink
            to="/library"
            className={({ isActive }) =>
              isActive ? activeLink : "hover:underline"
            }
          >
            e - Library
          </NavLink>
          <vr className="w-[2px] mx-3 bg-gray-700 border-0" />
          <NavLink
            to="/team"
            className={({ isActive }) =>
              isActive ? activeLink : "hover:underline"
            }
          >
            Team
          </NavLink>
          {/* <vr className="w-[2px] mx-3 bg-gray-700 border-0" /> */}

        </div>
      </header>
      <hr className="h-px border-0 bg-gray-700" />
    </div>
  );
};

export default Header;
