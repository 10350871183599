import React from "react";

const Disclaimer = () => {
  return (
    <div className="mt-16 mx-2 sm:mx-56 text-center">
      <h1 className="text-2xl font-semibold my-5">Disclaimer</h1>
      <p>
        The matter related to any writing published in this e-Magazine solely
        goes to the author of the same and in no circumstances publisher,
        editors, domain owner or developers are liable to or responsible for any unwanted happenings.
      </p>
      <p>
        The research papers and content available in our e-Library are submitted
        by authors who are part of চির সবুজ community. These authors have
        willingly contributed their work to share valuable insights and
        knowledge with the readers.{" "}
      </p>

      <br />
      <br />
      <br />
      <h1 className="text-2xl font-bold">Attribution</h1>
      <a
        href="https://www.flaticon.com/free-icons/agriculture"
        title="agriculture icons"
      >
        Agriculture icon created by Freepik - Flaticon
      </a>
      <p>
        Plant protection Image by{" "}
        <a href="https://www.freepik.com/free-photo/close-up-male-hands-holding-soil-little-plant_12060298.htm#query=plant%20breeding&position=22&from_view=keyword&track=ais">
          Freepik
        </a>
      </p>
      <p>
        Plant Breeding{" "}
        Image by <a href="https://www.freepik.com/free-photo/front-view-growing-plants_20825300.htm#query=plant%20breeding&position=42&from_view=search&track=ais">Freepik</a>
      </p>
    </div>
  );
};

export default Disclaimer;
