import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import data from "../db.json";
import NotFound from "./NotFound";

const Article = () => {
  const { id } = useParams();
  const article = data.articles[id - 1];
  const navigate = useNavigate();
  return (
    <>
      {article ? (<div>
      <h1 className="text-3xl font-bold mt-7 px-1.5">{article?.title}</h1>
      <h2 className="font-semibold text-xl mt-5">{article?.author}</h2>
      <div
        className={`m-3 px-3 text-left ${
          article?.poem ? "mt-5 text-center" : "sm:text-justify"
        } sm:m-7 sm:px-16`}
      >
        {article?.content?.map((paragraph, index) => (
          <p key={index} className=" ">
            {paragraph}
          </p>
        ))}
      </div>
      <div className="m-3 flex items-center justify-center flex-wrap gap-3 sm:gap-10">
        {article?.images?.map((image, index) => {
          return (
            <div className="">
              <img
                key={index}
                className="h-64 w-auto m-auto"
                src={`/images/${image.url}`}
                alt="article"
              />
              <p className="m-2">{image.caption}</p>
            </div>
          );
        })}
      </div>
      <div className="flex">
        <button
          className="m-5 inline-flex items-center border-2 border-gray-800 p-2 rounded-lg cursor-pointer hover:bg-black hover:text-white hover:font-bold"
          onClick={() => navigate(-1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="-ml-1 mr-2 h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          Back
        </button>
      </div>
    </div>): (<NotFound />)}
    </>
    
  );
};

export default Article;
