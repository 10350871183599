import React from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import NotFound from "../NotFound";
import data from "../../db.json";

const LibrarySection = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const section = data.library.find((item) => item.title === id);

  return (
    <>
      {section ? (
        <div>
          <h1 className="text-3xl font-semibold my-5">{section?.title}</h1>
          <div className="flex flex-col sm:flex-row flex-wrap justify-center">
            {section?.papers?.length === 0 && (
              <h1 className="text-2xl mt-10">Coming soon . . .</h1>
            )}
            {section?.papers?.map((paper, index) => {
              return (
                <div className="sm:w-1/4 flex flex-col justify-between m-5 rounded-lg shadow-2xl text-left p-5">
                  <div>
                    <h1 className="text-2xl ">{paper.title}</h1>

                    <p>{paper.author}</p>
                  </div>
                  <div className="flex justify-end">
                    <Link
                      target="_blank"
                      to={paper?.url}
                      className="border-2 border-gray-800 p-2 rounded-lg cursor-pointer hover:bg-black hover:text-white hover:font-bold"
                    >
                      Read
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex">
            <button
              className="m-5 inline-flex items-center border-2 border-gray-800 p-2 rounded-lg cursor-pointer hover:bg-black hover:text-white hover:font-bold"
              onClick={() => navigate(-1)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="-ml-1 mr-2 h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg>
              Back
            </button>
          </div>
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default LibrarySection;
