import React from "react";
import { useNavigate } from "react-router-dom";

const LibraryCard = ({ title, path, id }) => {
  const navigate = useNavigate();
  return (
    <div
      className="max-w-sm rounded overflow-hidden shadow-lg flex flex-col items-center cursor-pointer"
      onClick={() => navigate(`/library/${id}`)}
    >
      <img
        className="w-full"
        src={`/images/${path}`}
        alt={title}
        style={{ height: "300px", width: "300px" }}
      />
      <div className="p-3">
        <div className="font-bold text-xl mb-2">{title}</div>
      </div>
    </div>
  );
};

export default LibraryCard;
