import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center">
      <h1 className="text-5xl font-bold my-10">Oops! You seem to be lost.</h1>
      <img 
        src="/images/NotFound.png"
        className="w-72 margin-auto"
        alt="Not Found"
      />
      <h1 className="text-5xl font-bold my-10">404 Page Not Found</h1>
      <button
          className="m-5 pl-45 inline-flex items-center border-2 border-gray-800 p-2 rounded-lg cursor-pointer hover:bg-black hover:text-white hover:font-bold"
          onClick={() => navigate("/")}
        >
         Go to Home 
        </button>
    </div>
  )
}