import "./App.css";
import { Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import Header from "./components/Header";
import MagazineHome from "./components/MagazineHome";
import LibraryHome from "./components/LibraryHome/LibraryHome";
import Article from "./components/Article";
import Team from "./components/Team";
import LibrarySection from "./components/LibraryHome/LibrarySection";
import MagazineCover from "./components/MagazineCover";
import Disclaimer from "./components/Disclaimer";
import NotFound from "./components/NotFound";
import ArticleRelease2 from "./components/ArticleRelease2";

function App() {
  return (
    <div className="text-center bg-home-background bg-fixed min-h-screen bg-no-repeat bg-cover">
      <Header />
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/disclaimer" element={<Disclaimer />}/>
          <Route path="/magazine" element={<MagazineCover />} />
          <Route path="/library" element={<LibraryHome />} />
          <Route path="/magazine/content" element={<MagazineHome />} />
          <Route path="/magazine/article/:id" element={<Article />} /> 
          <Route path="/magazine/release2/article/:id" element={<ArticleRelease2 />} /> 
          <Route path="/library/:id" element={<LibrarySection />} /> 
          <Route path="/team" element={<Team />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      <footer className="sticky top-[100vh] pb-2 pt-4 text-xs">Copyright &copy; 2024 চির সবুজ (The Evergreen)</footer>
    </div>
  );
}

export default App;
