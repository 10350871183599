import React from "react";

const Team = () => {
  return (
    <div className="flex flex-col">
      {/* <h1 className='text-2xl font-bold mb-3' >Teams</h1> */}
      <div className="mt-5">
        <h1 className="text-3xl">Editors</h1>
        <p className="text-2xl">~ : ~</p>
        <ul className="mt-3">
          <li>S. D. Chatterjee</li>
          <li>P. Bhattacharya</li>
          <li>P. Sen</li>
          <li>P. K. Bera</li>
        </ul>
      </div>
      <div className="mt-10">
        <h1 className="text-3xl">Developers</h1>
        <p className="text-2xl">~ : ~</p>
        <ul className="mt-3">
          <li>Madhusree Bera</li>
          <li>Chandan Banerjee</li>
        </ul>
      </div>
      <div className="mt-10">
        <h1 className="text-3xl">Designer</h1>
        <p className="text-2xl">~ : ~</p>
        <ul className="mt-3">
          <li>Madhuparna Bera</li>
        </ul>
      </div>
      <div className="mt-10">
        <h1 className="text-3xl">Published by</h1>
        <p className="text-2xl">~ : ~</p>
        <ul className="mt-3">
          <li>Pulak Kumar Bera</li>
          <li>(On behalf of Retired Research Family)</li>
        </ul>
      </div>
    </div>
  );
};

export default Team;
