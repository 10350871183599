import React from "react";
import LibraryCard from "./LibraryCard";
import data from "../../db.json";

const LibraryHome = () => {
  return (
    <div>
      <div className="mx-8 my-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {data?.library?.map((item, index) => {
          return (
            <LibraryCard key={index} id={item.title} title={item.title} path={item.path} />
          );
        })}
      </div>
    </div>
  );
};

export default LibraryHome;
