import React from "react";

import Lottie from "lottie-react";
import Magazineanimation from "../animation/magazine_animation.json";
import library_animation from "../animation/library_animation.json";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();

  return (
    <div>
      {" "}
      <div className="mt-4 sm:mt-16">
        <h2 className="text-xl sm:text-2xl p-2">
          A unique venture of a social group of Retired Individuals to publish -
        </h2>
        <div className="flex flex-col gap-2 sm:gap-16 sm:flex-row sm:justify-center sm:mt-5">
          <div
            onClick={() => navigate("/magazine")}
            className="px-3 mx-3 hover:scale-110 transition duration-500 cursor-pointer"
          >
            <Lottie
              className="h-36 sm:h-56"
              animationData={Magazineanimation}
              loop={true}
            />
            <h3>
              <strong>চির সবুজ</strong>
              <br /> e - Magazine
            </h3>
          </div>
          <div
            onClick={() => navigate("/library")}
            className="px-3 mx-3 hover:scale-110 transition duration-500 cursor-pointer"
          >
            <Lottie
              className="h-36 sm:h-52 sm:mb-4"
              animationData={library_animation}
              loop={true}
            />
            <h3>
              The Evergreen
              <br /> e - Library
            </h3>
          </div>
          
        </div>
        <p className="mt-10 mx-5 italic">
          By: <br />
          Retired Agricultural Research Family, Department of Agriculture, West Bengal
          <br />
          (A Voluntary Group of Retired Individuals)
        </p>
         <img src="/images/research_family.jpg" alt="research_family" className="m-auto my-10 p-2"/>
                 
      </div>
    </div>
  );
};

export default Home;
