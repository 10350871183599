import React from "react";
import { useNavigate } from "react-router-dom";

const MagazineCover = () => {
  const navigate = useNavigate();
  return (
    <div className="sm:bg-magazine-background bg-fixed min-h-screen bg-no-repeat bg-cover">
      <div className="sm:flex sm:flex-col">
        <button
          className="self-end sm:mr-10 text-5xl sm:text-gray-300 sm:hover:text-gray-100"
          onClick={() => navigate("/magazine/content")}
          title="click to open magazine"
        >
          {" "}
          <img
            className="h-screen sm:h-0"
            src="/images/magazine_cover_mobile.png"
            alt="magazine cover"
          />
          ↠ 
        </button>
      </div>
    </div>
  );
};

export default MagazineCover;
